import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';
import 'magnific-popup';

/*
 * Atoms
 */
// import floatingCta from '../patterns/01-atoms/buttons/button-floating-cta/button-floating-cta';
import scrollToTop from '../patterns/01-atoms/scroll-to-top/scroll-to-top';
/*
 * Molecules
 */
import showAccordion from '../patterns/02-molecules/accordions/accordion/accordion';
import debugControls from '../patterns/02-molecules/debug-controls/debug-controls';
import dropdown from '../patterns/02-molecules/dropdowns/dropdown/dropdown';
// import gallery from '../patterns/02-molecules/galleries/gallery/gallery';
// import mapBlock from '../patterns/02-molecules/maps/map/map';
import modal from '../patterns/02-molecules/modals/modal/modal';
import { hamburger } from '../patterns/02-molecules/nav/hamburger/hamburger';
// import navigateCategory from '../patterns/02-molecules/nav/nav-category/nav-category';
import navMob from '../patterns/02-molecules/nav/nav-mobile/nav-mobile';
// import searchBar from '../patterns/02-molecules/search/search-bar/search-bar';
import { slideOut } from '../patterns/02-molecules/slide-outs/slide-out/slide-out';
// import sliderImages from '../patterns/02-molecules/sliders/slider-images/slider-images';
// import sliderTestimonials from '../patterns/02-molecules/sliders/slider-testimonials/slider-testimonials';
import socialShare from '../patterns/02-molecules/social/social-share/social-share';
import tabs from '../patterns/02-molecules/tabs/tabs/tabs';
import header from '../patterns/03-organisms/custom/custom-header/header';
import arHeroInner from '../patterns/03-organisms/custom/custom-hero-types/ar-hero-inner/ar-hero-inner';
/*
 * Organisms
 */
import arHeroLarge from '../patterns/03-organisms/custom/custom-hero-types/ar-hero-large/ar-hero-large';
// import defaultFilter from '../patterns/03-organisms/default/default-filter/default-filter';
// import defaultSecondaryNavbar from '../patterns/03-organisms/default/default-secondary-navbar/default-secondary-navbar';
// import defaultTeamMembers from '../patterns/03-organisms/default/default-team/default-team';
// import defaultArticleSideNavigation from '../patterns/04-templates/default/default-article-scrolling-nav-single/default-article-scrolling-nav-single';
/*
 * Misc
 */
import parallaxScroll from './misc/parallax/parallax-scroll';
import scrollToAnchor from './misc/scroll/scroll-to-anchor';

/*
 * Plop Entry (do not remove)
 */
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const app = {
	showAccordion,
	// sliderImages,
	// sliderTestimonials,
	header,
	// navigateCategory,
	// searchBar,
	scrollToTop,
	// floatingCta,
	socialShare,
	hamburger,
	slideOut,
	modal,
	debugControls,
	tabs,
	// mapBlock,
	arHeroLarge,
	arHeroInner,
	// defaultArticleSideNavigation,
	// defaultTeamMembers,
	// defaultSecondaryNavbar,
	// defaultFilter,
	dropdown,
	// gallery,
};

let calledModulesNames = [];
app.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in app) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new app[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	scrollToAnchor($);
	navMob($);

	// Component functions that should only run if the component exists in the ajax container or page.
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			app.instantiate(this);

			calledModulesNames.push($(this).data('module'));
		}
	});
});

jQuery(window).scroll(function() {
	parallaxScroll($);
});
