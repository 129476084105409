import { Elastic, Power3, TimelineMax, TweenMax } from 'gsap';

import SplitText from '../../../../../js/vendors/gsap/splittext';

function arHeroLarge() {
	const title = $('.js-split-text-title'),
		button = $('.js-hero-button'),
		hero = $('.js-hero');

	$(document).ready(function() {
		// Adding a class when the document is ready AND the hero image is loaded.
		$('.js-hero-img')
			.one('load', function() {
				$(hero).removeClass('o-hero--non-event');
				$(hero).addClass('o-hero--loaded');

				if (title.length) {
					const tlTitle = new TimelineMax({
							delay: 0.5,
							onStart: () => {
								TweenMax.set(title, {
									visibility: 'visible',
								});
							},
						}),
						titleSplitted = new SplitText(title, {
							type: 'chars,words',
						}),
						titleWords = titleSplitted.chars;

					// Text animation
					tlTitle.staggerFrom(
						titleWords,
						1,
						{
							y: 5,
							autoAlpha: 0,
							rotation: 1,
							ease: Power3.easeOut,
						},
						0.05,
						'+=0',
					);
				}

				// Check if button class is set
				if (button.length) {
					TweenMax.from(button, 2, {
						delay: 2.3,
						scale: 0.5,
						autoAlpha: 0,
						ease: Elastic.easeOut,
						onComplete: completeHandler,
					});
				}

				// Clicking the start button
				$(button).click(function() {
					$('html, body').animate(
						{
							scrollTop: $('#introduction').offset().top,
						},
						600,
					);
				});

				function completeHandler() {
					$(button).addClass('c-hero-1__cta--loaded');
					// $(logo).addClass('c-hero-1__cta--loaded');
				}
			})
			.each(function() {
				if (this.complete) {
					$(this).trigger('load'); // For jQuery >= 3.0
				}
			});
	});
}

export default arHeroLarge;
